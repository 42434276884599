<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("research-edit.header") }}</h3>
      <ResearchForm
        :research="data.research"
        :onSubmit="onSubmit"
        :diagnostics="data.diagnostics"
        v-if="data != null"
      />
    </div>
  </div>
</template>

<script>
import InitResearchEditInteractor from "@/business/research-edit/init-research-edit";
import SubmitFormInteractor from "@/business/research-edit/submit-form";
import ResearchEditScreenController from "@/adapters/controllers/screen-research-edit";
import ResearchForm from "@/application/components/researches/ResearchForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "research-edit",
  components: { ResearchForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initResearchEdit: null,
        submitForm: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ResearchEditScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initResearchEdit = this.$injector.get(
      InitResearchEditInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initResearchEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      delete this.data.research.dataSource.id;
      this.interactors.submitForm.handle(this.data.research);
    }
  }
};
</script>

<style lang="scss"></style>
